<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Control de dinero
            </div>

            <!-- <v-row>
                <v-col cols="4" sm="7" md="5" lg="4" xl="3">
                    Filtro
                </v-col>
            </v-row> -->

            <v-row class="mt-4 mb-2" no-gutters>
                <v-col cols="9" sm="5" md="3" lg="3" xl="3">
                    <ElemCatFind
                        class="mr-2 my-1"
                        ref="elemCatFind"
                        v-model="newElemCat"
                        :elemCatIdParent="2"
                        :orderById="true"
                        label="Tipo">
                    </ElemCatFind>
                </v-col>
                <v-col cols="3" sm="7" md="9" lg="9" xl="9">
                    <v-btn
                        class="my-1" height="38"
                        color="primary"
                        :disabled="newElemCat == null || newElemCat.ElemCatId == null"
                        @click="onClickNuevoControl">
                        Nuevo
                    </v-btn>
                </v-col>
            </v-row>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarControl"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.DinTmp="{ item }">
                    {{ formatDate(item.DinTmp) }}
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ElemCatFind from "../components/ElemCatFind.vue";
export default ({
    components: {
        ElemCatFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Fecha', width:106, sortable: false, value: 'DinTmp' },
                { text: 'Tipo', width:151, sortable: false, value: 'ElemCat.ElemCatNom' },
                { text: 'Controlado por', width:300, sortable: false, value: 'Per.PerNom' }
            ],
            dtOptionsLoaded: false,
            newElemCat: null
            // filtros: {
            // }
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded) {
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            DirId: this.$store.state.dir.DirId,
            EmpId: this.empId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/din", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    items,
                    total
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar los controles de dinero.');
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items;
          this.totalDataRows = data.total;
        });
      },
      onClickNuevoControl() {
        this.setDefaultData();
        this.$router.push('/control-dinero-nuevo/');
      },
      onClickRowEditarControl(item) {
        this.setDefaultData();
        this.$router.push('/control-dinero-editar/' + item.DinId);
      },
      setDefaultData () {
        this.$store.state.DefaultElemCatId = this.newElemCat != null ? this.newElemCat.ElemCatId : null;
        this.$store.state.LoadDinElems = true;
      },
      formatDate (value) {
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
      }
    } 
})
</script>
