<template>
    <v-autocomplete
          v-model="value"
          cache-items
          dense
          :small-chips="smallChips"
          :deletable-chips="deletableChips"
          hide-selected
          :items="itemsList"
          :search-input.sync="searchItem"
          open-on-clear
          item-value="ElemCatId"
          item-text="ElemCatNom"
          outlined
          no-data-text="Introduzca texto a buscar"
          :label="label"
          :readonly="readonly"
          return-object
          ref="ElemCatFindCtrl"
          :clearable="!readonly"
          :multiple="multiple"
          :hide-details="hideDetails"
          :menu-props="{'open-on-click': true}"
          @click="clickEvt"
          @click:append="clickAppendEvt"
          @input="inputEvt"
          @change="changeEvt"
          @update:search-input="UpdateSearchInputEvt"
          @blur="blurEvt">
      </v-autocomplete>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: [
      'value', 'elemCatIdParent', 'showParentsOnly', 'showNoParentOnly', 'elemCatToHide', 
      'label', 'multiple', 'readonly', 'smallChips', 'deletableChips', 'hideDetails',
      'selectOnReadonly', 'orderById'
    ],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      value() {
        if (this.value != null) {
          this.loadItems();
        }
      },
      elemCatIdParent() {
        this.$refs.ElemCatFindCtrl.cachedItems = [];
        this.loadItems();
      },
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
          Action: 'GET_DATA_LIST_PAGINATED',
          NumRegsPag: 35,
          NumPag: 1,
          EmpId: this.empId,
          ElemCatNom: valFind,
          ShowParentsOnly: this.showParentsOnly,
          ShowNoParentOnly: this.showNoParentOnly,
          ElemCatIdParent: this.elemCatIdParent,
          OrderById: this.orderById,
          Searcher: true
        };

        var valorActual = this.value;
        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/elemcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          this.itemsList = [];
          const idsToHide = this.elemCatToHide != null ? this.elemCatToHide.map(m => m.ElemCatId) : [];
          result.data.EntsList.forEach(element => {
            if(idsToHide.indexOf(element.ElemCatId) == -1) {
              var itemObject = {
                ElemCatId: element.ElemCatId, 
                ElemCatNom: element.ElemCatNom
              }
              this.itemsList.push(itemObject);
            }
          });

          if (valorActual != null) { 
            // if (valorActual.length == null) {
            if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.ElemCatId != null) {
                  if (!this.itemsList.some(itm=>itm.ElemCatId === valorActual.ElemCatId)) {
                    this.itemsList.push(valorActual);
                  }
                }
            }
            else {
              valorActual.forEach(element => {  // es un array
                if (!this.itemsList.some(itm=>itm.ElemCatId === element.ElemCatId)) {
                  this.itemsList.push(element);
                }
              });
            }
          }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.ElemCatFindCtrl.isMenuActive) {
            this.$refs.ElemCatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.ElemCatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.ElemCatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      UpdateSearchInputEvt() {
        this.$emit('update:search-input', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems();
    }
}
</script>
